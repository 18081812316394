import React, { useState, useCallback } from "react";
import { PageProps, navigate } from "gatsby";
import { Element } from "domhandler/lib/node";
import parse, { HTMLReactParserOptions } from "html-react-parser";
import { createHtmlParserOptions } from "../utils/htmlParser";
import { CountryKey } from "../translations/countries";
import styled from "styled-components";
import { findCountryInPathname, tryFindBreadcrumbLabel, getViewpointPathname, findViewpointTemplate, getInitialViewpoint } from "../utils/path";
import {
  FeedbackContext,
} from "../utils/contexts/feedbackContext";
import Topics from "../components/organisms/topics";
import { ViewpointSelection } from "../components/organisms/viewpointSelection";
import { rhythm } from "../utils/typography";
import { SelectionType } from "../components/molecules/eitherOneSelector";
import AnonymousFeedback from "../components/organisms/anonymousFeedback";
import { selectionContentId } from "../components/atoms/contentlink";
import BaseWrapper from "../components/molecules/BaseWrapper";

type Viewpoint = {
  country_a_page_id_template: string;
  country_b_page_id_template: string;
  country_a_header: string;
  country_b_header: string;
  viewpoint_selection_title: string;
  viewpoint_info_html?: string;
};

type PageContext = {
  pageId: string;
  html: string;
  languageCode: string;
  viewpoint: Viewpoint;
  breadcrumbLabel?: string;
  hasFeedback: boolean;
};

const StyledAnonymousFeedback = styled(AnonymousFeedback)`
  margin-top: ${rhythm(3)};
`;

const StyledTopics = styled(Topics)`
  margin-top: ${rhythm(3)};
`;

const StyledViewpointSelection = styled(ViewpointSelection)`
  margin-bottom: ${rhythm(2)};
`;

const PageTemplate = (data: PageProps) => {
  const { pageContext, location } = data;

  const {
    pageId,
    html: rawHtml,
    languageCode,
    viewpoint,
    breadcrumbLabel,
    hasFeedback,
  } = pageContext as PageContext;
  const headers: Array<{ level: number; header: any }> = [];
  const initialOptions = createHtmlParserOptions(languageCode);
  const parserOptions: HTMLReactParserOptions = {
    ...initialOptions,
    replace: (domNode) => {
      if (domNode instanceof Element) {
        switch (domNode.type) {
          case "tag":
            // Try to find suitable headers to act as a breadcrumb label.
            const { level, header } = tryFindBreadcrumbLabel(domNode, pageId);
            if (level && header) {
              headers.push({ level, header });
            }
            break;
          default:
            break;
        }

        const component =
          initialOptions?.replace && initialOptions.replace(domNode);
        if (component) {
          return component;
        }
      }
    },
  };

  const parsedHtml = parse(rawHtml, parserOptions);

  const [selectedCountry, setSelectedCountry] = useState<CountryKey | undefined>(
    findCountryInPathname(location.pathname)
  );

  const [selectedViewpointTemplate, setSelectedViewpointTemplate] = useState(
    viewpoint && selectedCountry
      ? findViewpointTemplate(
          location.pathname,
          selectedCountry,
          languageCode,
          [
            viewpoint.country_a_page_id_template,
            viewpoint.country_b_page_id_template,
          ]
        )
      : null
  );

  const [selectedViewpoint, setSelectedViewpoint] = useState<SelectionType>(
    getInitialViewpoint(viewpoint, selectedViewpointTemplate ?? "")
  );

  const handleCountrySelection = useCallback(
    (countryKey: CountryKey) => {
      setSelectedCountry(countryKey);
      if (countryKey && selectedViewpointTemplate) {
        const newPathName = getViewpointPathname(
          countryKey,
          languageCode,
          selectedViewpointTemplate ?? ""
        );

        // Prevent unneccessary navigations when entering by link
        if (location.pathname !== newPathName)
          navigate(newPathName);
      }
    },
    [
      navigate,
      location,
      languageCode,
      selectedCountry,
      selectedViewpointTemplate,
    ]
  );

  const handleViewpointSelection = useCallback(
    (selected: SelectionType) => {
      let selectedTemplate: string | null = null;
      setSelectedViewpoint(selected)
      if (selected === "one") {
        selectedTemplate = viewpoint.country_a_page_id_template;
      } else if (selected === "another") {
        selectedTemplate = viewpoint.country_b_page_id_template;
      }
      setSelectedViewpointTemplate(selectedTemplate);

      if (selectedViewpointTemplate && selectedCountry) {
        const newPathName = getViewpointPathname(
          selectedCountry,
          languageCode,
          selectedTemplate ?? ""
        );
        // Prevent unneccessary navigations when entering by link
        if (location.pathname !== newPathName)
          navigate(newPathName);
      }
    },
    [navigate, location, languageCode, viewpoint, selectedCountry]
  );

  const viewpointInfo =
    viewpoint && viewpoint.viewpoint_info_html
      ? parse(viewpoint.viewpoint_info_html, parserOptions)
      : undefined;

  const hasViewpoint = !!viewpoint;
  let crumbLabel = breadcrumbLabel;
  if (!crumbLabel) {
    crumbLabel = headers.length > 0 ? headers[0].header : pageId;
  }

  return (
    <BaseWrapper
      initialFeedbackState={{ showSendFeedback: true }}
      crumbLabel={crumbLabel}
      SEOtitle={crumbLabel}
      location={location}>
      {viewpoint && (
        <StyledViewpointSelection
          title={viewpoint.viewpoint_selection_title}
          sourceText={viewpoint.country_a_header}
          targetText={viewpoint.country_b_header}
          onCountrySelected={handleCountrySelection}
          onViewpointSelected={handleViewpointSelection}
          selectedCountry={selectedCountry}
          selectedViewpoint={selectedViewpoint}
          viewpointInfo={viewpointInfo}
        />
      )}
      {(!hasViewpoint || (hasViewpoint && selectedCountry)) && <section aria-live="polite" id={selectionContentId}>{parsedHtml}</section>}
      <FeedbackContext.Consumer>
        {({ showSendFeedback }) =>
          hasFeedback && showSendFeedback && <StyledAnonymousFeedback />
        }
      </FeedbackContext.Consumer>
      <StyledTopics />
    </BaseWrapper>
  );
};

export default PageTemplate;
